import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { useState } from "react";

export function useStripeCard() {
  const [errorCard, setErrorCard] = useState("");

  function onCardNumberChange({
    complete,
    error,
    empty,
  }: StripeCardElementChangeEvent) {
    if (error) {
      setErrorCard(error.message);
    } else if (errorCard) {
      setErrorCard("");
    }
  }

  return {
    errorCard,
    onCardNumberChange,
  };
}
