import { SubscriptionProvider, usePlansQuery } from "services/graphql";
import { useIPCountryCode } from "modules/selectors";

export const usePlans = () => {
  const countryCode = useIPCountryCode();

  const { data, loading, error, refetch } = usePlansQuery({
    variables: {
      providerName: SubscriptionProvider.Chargebee,
      countryCode: countryCode ?? "US", // Default to US if we don't have a country code
    },
  });

  return { data, loading, error, refetch };
};
