import { useEffect, useRef, useState } from "react";
import { GatewayProvider, PaymentMethodType } from "services/graphql";
import env from "helpers/env";
import { useCheckout } from "modules/selectors";
import { OnApproveData } from "@paypal/paypal-js";
import { CheckoutError } from "constants/cart";
import { useCreateSubscription } from "hooks/Checkout/useCreateSubscription";

interface Props {
  onSuccess?(): void;
}

export function useCheckoutWithPaypal(props?: Props) {
  const { onSuccess } = props || {};
  const paypalRef = useRef(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { selectedPlan } = useCheckout();

  const { createSubscription } = useCreateSubscription({ onSuccess });

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    async function loadPaypalClient() {
      const imports = [
        import(
          /* webpackChunkName: "braintree-client" */ "braintree-web/client"
        ),
        import(
          /* webpackChunkName: "braintree-paypal-checkout" */ "braintree-web/paypal-checkout"
        ),
      ];
      const [braintreeClient, paypalCheckout] = await Promise.all(imports);
      const clientInstance = await braintreeClient.create({
        authorization: env("PUBLIC_BRAINTREE_AUTH_TOKEN"),
      });
      const paypalInstance = await paypalCheckout.create({
        client: clientInstance,
      });
      paypalRef.current = paypalInstance;
    }

    loadPaypalClient().catch(err => {
      setError(err);
    });
  }, []);

  async function checkoutPaypal(onApproveBraintreeData: OnApproveData) {
    setLoading(true);

    if (!(paypalRef.current && selectedPlan)) {
      setError(CheckoutError.Paypal);
      return;
    }

    setError("");

    try {
      const { nonce: token } = await paypalRef.current.tokenizePayment(
        onApproveBraintreeData as any
      );

      await createSubscription({
        gatewayProvider: GatewayProvider.Braintree,
        paymentMethodType: PaymentMethodType.PaypalExpressCheckout,
        token,
      });
    } catch (e) {
      setLoading(false);
      setError(CheckoutError.SubscriptionCreate);
    }
  }

  async function createBillingAgreement() {
    if (!paypalRef.current) {
      setError(CheckoutError.Generic);
      return "";
    }

    return paypalRef.current.createPayment({ flow: "vault" as any });
  }

  return {
    paypal: {
      error,
      loading,
      checkoutPaypal,
      createBillingAgreement,
    },
  };
}
