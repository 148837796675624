export enum AbbreviatedPeriodUnits {
  Year = "yr",
  year = "yr",
  Month = "mo",
  month = "mo",
}

export enum PeriodUnitAdverbs {
  Year = "Yearly",
  Month = "Monthly",
  year = "Yearly",
  month = "Monthly",
}

export enum AnalyticsModule {
  Onboarding = "Onboarding",
}

export enum AnalyticsLocation {
  Checkout = "Checkout",
  Onboarding = "onboarding",
}

export enum CheckoutError {
  Generic = "Something went wrong. Please try again.",
  SubscriptionCreate = "There was an error creating your subscription; please contact customer support for help.",
  Paypal = "There is a problem checking out with PayPal right now. Please try a different payment method.",
  PaymentMethod = "There is a problem checking out with that payment method right now. Please try a different payment method.",
}
