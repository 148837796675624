import { BillingPeriodUnit } from "services/graphql";

export function determineAnnualOrMonthlyText(
  annualText: string,
  monthlyText: string,
  period?: BillingPeriodUnit | null
) {
  const map: any = {
    [BillingPeriodUnit.Year]: annualText,
    [BillingPeriodUnit.Month]: monthlyText,
  };

  return (period && map[period]) || "";
}
