import {
  checkoutSelectElement,
  subscribeSelectPlan,
} from "services/typewriter/segment";
import { BillingPeriodUnit, Plan, PlansQuery } from "services/graphql";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { useCheckout } from "modules/selectors";
import { determineAnnualOrMonthlyText } from "helpers/determineAnnualOrMonthlyText";
import { AnalyticsLocation, AnalyticsModule } from "constants/cart";

export interface TieredPlans {
  [accessType: string]: Plan;
}

type TieredPlansGroupedByBillingPeriod = {
  [key in BillingPeriodUnit]: TieredPlans;
};

interface Props {
  plans: PlansQuery["plans"] | undefined;
  billingPeriodUnit: BillingPeriodUnit;
}

export function useTieredPlans({ plans, billingPeriodUnit }: Props) {
  const { selectedPlan } = useCheckout();
  const { pathname } = useLocation();

  const tieredPlansGroupedByBillingPeriod = useMemo(() => {
    if (!plans) {
      return null;
    }

    const out: any = {
      [BillingPeriodUnit.Year]: {},
      [BillingPeriodUnit.Month]: {},
    };

    // Assumes there are only one of each period unit.
    plans.forEach((p: any) => {
      if (p.periodUnit === BillingPeriodUnit.Year) {
        out[BillingPeriodUnit.Year][p.accessType] = p;
      }

      if (p.periodUnit === BillingPeriodUnit.Month) {
        out[BillingPeriodUnit.Month][p.accessType] = p;
      }
    });

    return out as TieredPlansGroupedByBillingPeriod;
  }, [plans]);

  const tieredPlans = useMemo(() => {
    if (!tieredPlansGroupedByBillingPeriod) {
      return null;
    }

    const groupedPlans = tieredPlansGroupedByBillingPeriod[billingPeriodUnit];

    return Object.keys(groupedPlans)
      .map(key => groupedPlans[key])
      .sort((a, b) => a.priceInCents - b.priceInCents);
  }, [tieredPlansGroupedByBillingPeriod, billingPeriodUnit]);

  useEffect(() => {
    if (!(selectedPlan && tieredPlans)) {
      return;
    }

    subscribeSelectPlan({
      plan: selectedPlan.provider.id,
      path: pathname,
      module: AnalyticsModule.Onboarding,
    });

    const unit = determineAnnualOrMonthlyText(
      "Annual",
      "Monthly",
      selectedPlan.periodUnit
    );

    checkoutSelectElement({
      element_name: `Checkout - ${unit} Plan Button`,
      element_type: "Button",
      location: AnalyticsLocation.Checkout,
      module: AnalyticsModule.Onboarding,
    });
  }, [tieredPlans, selectedPlan]);

  return {
    tieredPlans,
  };
}
