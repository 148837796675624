import { GatewayProvider, PaymentMethodType } from "services/graphql";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Token } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useCheckout } from "modules/selectors";
import { CheckoutError } from "constants/cart";
import { useCreateSubscription } from "./useCreateSubscription";

interface Props {
  onSuccess?(): void;
}

export function useCheckoutWithStripe(props?: Props) {
  const { onSuccess } = props || {};
  const stripe = useStripe();
  const elements = useElements();
  const { selectedPlan } = useCheckout();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { createSubscription } = useCreateSubscription({ onSuccess });

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  async function tokenize() {
    if (!(elements && stripe)) {
      setError(CheckoutError.Generic);
      return null;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setError(CheckoutError.Generic);
      return null;
    }

    const { token, error: tokenError } = await stripe.createToken(cardElement);

    if (tokenError || !token) {
      setError(tokenError.message ?? CheckoutError.Generic);
      return null;
    }

    setError("");

    return token;
  }

  async function checkoutStripe(_token?: Token) {
    const token = _token || (await tokenize()); // Use token if given (by providers).

    if (!(token && selectedPlan)) {
      setError(CheckoutError.Generic);
      return;
    }

    try {
      /**
       * @IMPORTANT https://www.daviddarke.dev/blog/fixing-stripe-integrationerror/
       * Can't set loading until tokenization is done,
       * otherwise the Element that has the card is gone.
       */
      //
      setLoading(true);

      try {
        await createSubscription({
          gatewayProvider: GatewayProvider.Stripe,
          paymentMethodType: PaymentMethodType.Card,
          token: token.id,
        });
      } catch (e) {
        setError(e as any);
      }
    } catch (e) {
      setError(CheckoutError.SubscriptionCreate);
    }
  }

  return {
    stripe: {
      error,
      loading,
      checkoutStripe,
    },
  };
}
